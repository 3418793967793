import { useState } from "preact/hooks";
import {
  MdArrowForward, MdArrowRight, MdCreate, MdNavigateNext, MdPlace,
} from "react-icons/md";
import classNames from "../../../utility/classNames";
import DetailModal from "../components/DetailModal";
import TabbedNavigation from "../components/TabbedNavigation";

function Location({ id }) {
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  if (error) {
    return (
      <div className={classNames(
        "my-16 text-center",
      )}
      >
        <h1 className="text-2xl font-bold text-red-500">Something went wrong.</h1>
        <div className={classNames(
          "text-gray-300",
          "mx-auto my-16",
          "w-fit rounded",
        )}
        >
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-screen-md transition duration-300">
      <div className={classNames(
        "bg-zinc-900/50 p-6 rounded-lg",
        "flex flex-col gap-6",
      )}
      >
        <TabbedNavigation />
        hello
        {" "}
        {id}
      </div>
    </div>
  );
}

export default Location;
