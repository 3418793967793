import {
  MdCameraAlt, MdViewModule,
} from "react-icons/md";
import classNames from "../../utility/classNames";

function IconButton({ children, href }) {
  return (
    <a
      href={href}
      className={classNames(
        "block shrink-0 rounded-lg p-2.5 text-gray-400 shadow-sm ",
        "border border-gray-500 hover:border-transparent",
        "bg-zinc-900 hover:bg-zinc-800 hover:text-gray-500",
        "transition duration-300 ease-in-out",
      )}
    >
      {children}
    </a>
  );
}
function Header() {
  return (

    <header className="bg-zinc-900 border-b-2 border-blue-600">
      <div className={classNames(
        "mx-auto max-w-screen-xl",
        "px-4 py-6 sm:px-4 lg:px-8",
      )}
      >
        <div className="flex items-center sm:gap-4">
          <img
            src="/assets/logo.svg"
            className="w-12 h-12"
            alt="logo"
          />

          <div
            className="flex flex-1 items-center gap-4 justify-end"
          >
            <div className="flex gap-4">
              <IconButton href="/photos">
                <MdCameraAlt className="w-full h-full" />
              </IconButton>

              <IconButton href="/gallery">
                <MdViewModule className="w-full h-full" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </header>

  );
}

export default Header;
