import classNames from "../../../../utility/classNames";

export default function Notes() {
  return (
    <textarea
      type="text"
      className={classNames(
        "w-full rounded-md border-gray-200 text-sm shadow-sm",
        "bg-zinc-700 focus:bg-zinc-700/75",
        "p-2 text-gray-300 text-sm mt-2",
        "transition duration-300 ease-in-out",
      )}
    />
  );
}
