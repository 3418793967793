import { Router } from "preact-router";

import Header from "./header";

import Home from "../routes/home";
import Gallery from "../routes/gallery";
import Login from "../routes/auth/login";
import Signup from "../routes/auth/signup";
import Locations from "../routes/locations";
import Location from "../routes/locations/location";
import LocationItems from "../routes/locations/location/items";

function App() {
  return (
    <div id="app" className="bg-zinc-800 w-screen h-screen">
      <Header />
      <main>
        <Router>
          <Home path="/" />
          {/* auth */}
          <Login path="/auth/login" />
          <Signup path="/auth/signup" />
          {/*  */}
          <Locations path="/locations" />
          <Location path="/locations/:id" />
          <LocationItems path="/locations/:id/items" />
          <Location path="/locations/:id/expiring" />
          <Location path="/locations/:id/out" />
        </Router>
      </main>
    </div>
  );
}

export default App;
