import { useEffect, useState } from "preact/hooks";
import { MdAdd, MdCheck, MdRemove } from "react-icons/md";
import classNames from "../../../../utility/classNames";

export default function Restock({ count: originalCount }) {
  const [isOpen, setOpen] = useState(false);
  const [count, _setCount] = useState(originalCount);

  const setCount = (c) => {
    if (c < 0) return;
    _setCount(c);
  };

  if (isOpen) {
    return (
      <>
        {`${count} `}
        in this location
        <div className={classNames(
          "p-1 ml-2 rounded-lg",
          "border border-gray-400",
          "flex flex-row gap-1 items-center",
        )}
        >
          <button
            type="button"
            className={classNames(
              "underline text-blue-500 hover:text-blue-600",
              "transition duration-300 ease-in-out",
            )}
            onClick={() => setCount(count + 1)}
          >
            <MdAdd className={classNames(
              "w-6 h-6 text-gray-500 hover:text-gray-600",
              "hover:bg-zinc-900 rounded-lg p-0.5",
              "transition duration-300 ease-in-out",
            )}
            />
          </button>
          <button
            type="button"
            className={classNames(
              "underline text-blue-500 hover:text-blue-600",
              "transition duration-300 ease-in-out",
            )}
            onClick={() => setCount(count - 1)}
          >
            <MdRemove className={classNames(
              "w-6 h-6 text-gray-500 hover:text-gray-600",
              "hover:bg-zinc-900 rounded-lg p-0.5",
              "transition duration-300 ease-in-out",
            )}
            />
          </button>
          <button
            type="button"
            className={classNames(
              "underline text-blue-500 hover:text-blue-600",
              "transition duration-300 ease-in-out",
            )}
            onClick={() => setOpen(false)}
          >
            <MdCheck className={classNames(
              "w-6 h-6 text-green-500 hover:text-green-600",
              "hover:bg-zinc-900 rounded-lg p-0.5",
              "transition duration-300 ease-in-out",
            )}
            />
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      {`${count} `}
      in this location
      <button
        type="button"
        className={classNames(
          "ml-1 underline text-blue-500 hover:text-blue-600",
          "transition duration-300 ease-in-out",
        )}
        onClick={() => setOpen(true)}
      >
        Restock?
      </button>
    </>
  );
}
