import { MdBarcodeReader, MdOutlineTopic, MdQrCodeScanner } from "react-icons/md";
import classNames from "../../../../utility/classNames";

export default function Data() {
  return (
    <table className={classNames(
      "w-full text-sm shadow-sm mt-2",
      "border-collapse border border-gray-500",
    )}
    >
      <tbody>
        <tr>
          <td className="border border-gray-500 p-4 text-gray-400">UPC</td>
          <td className="border border-gray-500 p-4 text-gray-400">
            <div className="flex items-center flex-row">
              <input
                type="text"
                className={classNames(
                  "w-full rounded-md border border-gray-500 text-sm shadow-sm",
                  "bg-transparent focus:bg-zinc-700/75",
                  "p-2 text-gray-300 text-sm",
                  "transition duration-300 ease-in-out",
                )}
              />
              <button
                type="button"
                className={classNames(
                  "ml-2 p-1  rounded-lg",
                  "bg-zinc-900/50 hover:bg-zinc-900",
                  "transition duration-300 ease-in-out",
                )}
              >
                <MdOutlineTopic className="w-8 h-8 text-gray-300" />
              </button>
              <button
                type="button"
                className={classNames(
                  "ml-2 p-1  rounded-lg",
                  "bg-zinc-900/50 hover:bg-zinc-900",
                  "transition duration-300 ease-in-out",
                )}
              >
                <MdQrCodeScanner className="w-8 h-8 text-gray-300" />
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td className="border border-gray-500 p-4 text-gray-400">Price</td>
          <td className="border border-gray-500 p-4 text-gray-400">
            <input
              type="text"
              className={classNames(
                "w-full rounded-md border border-gray-500 text-sm shadow-sm",
                "bg-transparent focus:bg-zinc-700/75",
                "p-2 text-gray-300 text-sm",
                "transition duration-300 ease-in-out",
              )}
            />
          </td>
        </tr>
        <tr>
          <td className="border border-gray-500 p-4 text-gray-400">Date created</td>
          <td className="border border-gray-500 p-4 text-gray-400">January 1, 1970</td>
        </tr>
        <tr>
          <td className="border border-gray-500 p-4 text-gray-400">Last bought</td>
          <td className="border border-gray-500 p-4 text-gray-400">January 1, 1970</td>
        </tr>
        <tr>
          <td className="border border-gray-500 p-4 text-gray-400">Michigan</td>
          <td className="border border-gray-500 p-4 text-gray-400">Detroit</td>
        </tr>
        <tr>
          <td className="border border-gray-500 p-4 text-gray-400">Michigan</td>
          <td className="border border-gray-500 p-4 text-gray-400">Detroit</td>
        </tr>
      </tbody>
    </table>
  );
}
