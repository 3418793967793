import { useState } from "preact/hooks";
import {
  MdArrowForward, MdArrowRight, MdCreate, MdNavigateNext, MdPlace,
} from "react-icons/md";
import classNames from "../../../utility/classNames";
import DetailModal from "../components/DetailModal";
import TabbedNavigation from "../components/TabbedNavigation";

function Item({
  name, variety, id, modal: [setModalOpen],
}) {
  return (
    <button
      type="button"
      className={classNames(
        "border border-gray-300/50 hover:border-gray-300/10",
        "text-gray-300 hover:bg-zinc-900",
        "rounded-lg p-4",
        "flex flex-row",
        "transition duration-300 ease-in-out",
      )}
      onClick={() => setModalOpen(true)}
    >
      <div className="my-auto text-left">
        <h1 className="text-xl font-bold">{name}</h1>
        <h1 className="text-sm">{variety}</h1>
        <div className="text-sm text-gray-500">2 in stock</div>
      </div>
      <div className="flex-grow" />
      <div className="my-auto">
        <img
          src="https://picsum.photos/500/500"
          alt="demo"
          className="rounded-lg h-auto max-h-20"
        />
      </div>
    </button>
  );
}

function LocationItems({ id }) {
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  if (error) {
    return (
      <div className={classNames(
        "my-16 text-center",
      )}
      >
        <h1 className="text-2xl font-bold text-red-500">Something went wrong.</h1>
        <div className={classNames(
          "text-gray-300",
          "mx-auto my-16",
          "w-fit rounded",
        )}
        >
          {error}
        </div>
      </div>
    );
  }

  return (
    <>

      <div className="mx-auto max-w-screen-md transition duration-300">
        <div className={classNames(
          "bg-zinc-900/50 p-6 rounded-lg",
          "flex flex-col gap-6",
        )}
        >
          <TabbedNavigation selected="items" id={id} />
          <Item name="peanuts" variety="special edition" modal={[setModalOpen]} />
        </div>
      </div>
      <DetailModal disclosure={[modalOpen, setModalOpen]} />
    </>
  );
}

export default LocationItems;
