import classNames from "../../../utility/classNames";

function Login() {
  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg text-center bg-blue-600 rounded-t-md p-6">
        <h1 className="text-2xl font-bold sm:text-3xl text-gray-100">Welcome back!</h1>
        <h1 className="text-lg text-gray-300">Great to see you again.</h1>
      </div>

      <form action="" className="mx-auto pt-10 p-6 max-w-lg bg-zinc-900 rounded-b-lg">
        <div>
          <h3
            htmlFor="username"
            className="text-lg font-black text-gray-200 mb-2"
          >
            Username
          </h3>

          <input
            type="password"
            className={classNames(
              "w-full rounded-md border-gray-200 text-sm shadow-sm",
              "bg-zinc-700 focus:bg-zinc-700/75",
              "p-3 mb-3 text-gray-300 text-sm",
              "transition duration-300 ease-in-out",
            )}
          />
        </div>

        <div>
          <h3
            htmlFor="password"
            className="text-lg font-black text-gray-200 mb-2"
          >
            Password
          </h3>

          <input
            type="password"
            className={classNames(
              "w-full rounded-md border-gray-200 text-sm shadow-sm",
              "bg-zinc-700 focus:bg-zinc-700/75",
              "p-3 mb-3 text-gray-300 text-sm",
              "transition duration-300 ease-in-out",
            )}
          />
        </div>

        <div className="flex items-center justify-between mt-6">
          <p className="text-sm text-gray-300">
            No account?
            <a className="underline ml-1" href="/auth/signup">Sign up</a>
          </p>

          <button
            type="button"
            className={classNames(
              "text-center rounded-md drop-shadow-lg",
              "px-4 py-2 w-fit inline-block text-gray-100",
              "bg-blue-600 hover:bg-blue-700",
              "transition duration-300 ease-in-out",
            )}
            onClick={() => {}}
          >
            Sign In
          </button>
          {/* <button
            type="submit"
            className={classNames(
              "inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white",
            )}
          >
            Sign in
          </button> */}
        </div>
      </form>
    </div>
  );
}

export default Login;
