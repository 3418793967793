import classNames from "../../../utility/classNames";

const links = [
  {
    href: "items",
    label: "Items",
    jsonKey: "162",
  },
  {
    href: "expiring",
    label: "Expiring soon",
    jsonKey: "2",
  },
  {
    href: "out",
    label: "Out",
    jsonKey: "5",
  },
];

export default function TabbedNavigation({ id, selected }) {
  return (
    <div>
      <div className="border-b border-gray-400">
        <nav className="-mb-px flex flex flex-row gap-6 w-full" aria-label="Tabs">
          {links.map(({ href, label, jsonKey }) => (
            <a
              href={`/locations/${id}/${href}`}
              className={classNames(
                "text-gray-400 hover:text-blue-600 text-sm font-medium",
                "inline-flex shrink-0",
                "gap-2 border-b-2 border-transparent hover:border-blue-600/75",
                "items-center  px-1 pb-4",
                selected === href ? "text-blue-500 border-blue-500" : "",
                "transition duration-2 00 ease-in-out flex-grow",
              )}
            >
              <div className="mx-auto flex items-center">
                {label}
                <div className="ml-1 inline-flex items-center justify-center p-2 h-5 text-xs font-bold text-white bg-red-500 rounded-full">{jsonKey}</div>
              </div>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
