import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "preact";
import { MdClose, MdDelete, MdShare } from "react-icons/md";
import classNames from "../../../utility/classNames";
import Restock from "./partial/Restock";
import Data from "./partial/Data";
import Notes from "./partial/Notes";

function Action({
  secondary,
  children,
  onClick,
  className,
}) {
  return (
    <button
      type="button"
      className={classNames(
        secondary ? "border border-transparent hover:border-gray-500" : "",
        secondary ? "hover:bg-zinc-900" : "bg-zinc-600 hover:bg-zinc-700",
        "w-8 h-8 rounded p-1",
        "transition duration-300 ease-in-out",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default function MyModal({ disclosure: [, setOpen] }) {
  const open = true;
  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={classNames(
                "w-full md:max-w-lg align-middle",
                "transform rounded-lg",
                "shadow-xl overflow-hidden",
                "text-left p-6",
                "bg-zinc-800 transition-all",
                "flex flex-col md:flex-row",
              )}
              >
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex-grow">
                    <h1 className="text-xl font-bold text-gray-100">peanuts</h1>
                    {/*  */}
                    <h1 className={classNames(
                      "text-sm font-medium text-gray-300",
                      "flex items-center flex-row",
                    )}
                    >
                      <Restock count={2} />
                    </h1>
                    {/*  */}
                    <Data />
                    <Notes />
                  </div>
                  <div className="flex flex-row gap-1">
                    <div className="flex-grow flex flex-row gap-2">
                      {/* <Action secondary>
                        <MdDelete className="w-full h-full text-gray-400" />
                      </Action>
                      <Action secondary>
                        <MdShare className="w-full h-full text-gray-400" />
                      </Action> */}
                    </div>
                    <Action onClick={closeModal}>
                      <MdClose className="w-full h-full text-red-500" />
                    </Action>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
